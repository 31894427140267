import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import messages from "../assets/messages.json"
import { Fade } from "react-reveal"

const BrandList: React.FC<any> = ({ hasBackground }) => {
    const language = useSelector((state: RootState) => state.inner.language)
    const data = useStaticQuery(graphql`
        query {
            drupal {
                nodeQuery(offset: 0, limit: 1000, filter: {conditions: [{field: "type", operator: EQUAL, value: ["marchi"]}]}) {
                    entities {
                    ... on Drupal_NodeMarchi {
                            fieldLink
                            fieldLogo {
                                alt
                                url
                            }
                            nid
                        }
                    }
                }
            }
        }
    `)
    return (
        <div className={`c-brandList ${hasBackground ? '' : 'noBg'}`}>
            <Fade bottom>
                <h6>{messages[language.toLowerCase()].ourBrands}</h6>
            </Fade>
        <Fade bottom cascade>
            <ul>
                {data.drupal.nodeQuery.entities.map((brand) => {
                    if (brand && brand.nid) {
                        return (
                            <li key={`brand-list-${brand.nid}`} className="rellax" data-rellax-speed="-0.4" data-rellax-xs-speed="0" data-rellax-mobile-speed="0" data-rellax-tablet-speed="-0.2">
                                <a href={'//' + brand.fieldLink} target="blank">
                                    <img src={brand.fieldLogo.url} alt={brand.fieldLogo.alt} />
                                </a>
                            </li>
                        )
                    }
                    return null
                })}
            </ul>
            </Fade>
        </div>
    )
}

export default BrandList