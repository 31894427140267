import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { Fade } from "react-reveal"

const Services: React.FC = () => {
  const language = useSelector((state: RootState) => state.inner.language)

  const data = useStaticQuery(graphql`
      query {
        customAdminServiceblockAdminServiceblockResourceIt {
          img_big
          img_little
          link_su_misura
          link_contract
          body
        }
        customAdminServiceblockAdminServiceblockResourceEn {
          img_big
          img_little
          link_su_misura
          link_contract
          body
        }
      }
    `)

  return (
    <div className="c-services">
      {
        language === 'IT' ?
          <>
            <div>
              <Fade right>
                <img src={data.customAdminServiceblockAdminServiceblockResourceIt.img_big} alt="service" />
              </Fade>
              <Fade bottom>
                <img src={data.customAdminServiceblockAdminServiceblockResourceIt.img_little} alt="service"/>
              </Fade>
            </div>
            <div className="rellax" data-rellax-speed="-1" data-rellax-xs-speed="0" data-rellax-mobile-speed="0" data-rellax-tablet-speed="-0.3">
              <Fade bottom>
                <h3 className="words">I nostri servizi</h3>
              </Fade>
              <Fade bottom>
                <div className="words" dangerouslySetInnerHTML={{ __html: data.customAdminServiceblockAdminServiceblockResourceIt.body }} />
              </Fade>
              <Fade bottom>
                <div className="l-flex">
                  <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceIt.link_contract} className="o-btn(angled)">
                    I nostri servizi contract
                </a>
                  <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceIt.link_su_misura} className="o-btn(angled)">
                    I nostri servizi su misura
                </a>
                  <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceIt.link_contract} className="o-btn(arrowRight)">
                    Scopri di più
                </a>
                </div>
              </Fade>
            </div>
          </>
          :
          <>
            <div>
              <Fade right>
                <img src={data.customAdminServiceblockAdminServiceblockResourceEn.img_big} alt="service" />
              </Fade>
              <Fade bottom>
                <img src={data.customAdminServiceblockAdminServiceblockResourceEn.img_little} alt="service" className="rellax" data-rellax-speed="-1" data-rellax-xs-speed="-0.2" data-rellax-mobile-speed="-0.2" data-rellax-tablet-speed="-0.5" />
              </Fade>
            </div>
            <div className="rellax" data-rellax-speed="-1" data-rellax-xs-speed="0" data-rellax-mobile-speed="0" data-rellax-tablet-speed="-0.3">
              <Fade bottom>
                <h3 >Our services</h3>
              </Fade>
              <Fade bottom>
                <div dangerouslySetInnerHTML={{ __html: data.customAdminServiceblockAdminServiceblockResourceEn.body }} />
              </Fade>
              <Fade bottom>
                <div className="l-flex">
                  <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceEn.link_contract} className="o-btn(angled)">
                    Our contract services
                </a>
                  <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceEn.link_su_misura} className="o-btn(angled)">
                    Our tailored services
                </a>
                  <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceEn.link_contract} className="o-btn(arrowRight)">
                    Find out more
                </a>
                </div>
              </Fade>
            </div>
          </>
      }
    </div>
  )
}

export default Services
