import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import AboutUs from "../components/about_us"
import Services from "../components/services"
import BrandList from "../components/brand_list"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { setPage } from "../redux/reducers/indexSlice"
import { RootState } from "../redux/store"
// import Presentation from "../components/presentation"
// import Promotions from "../components/promotions"
// import {PromotionItem} from "../components/promotions"

const IndexPage: React.FC<any> = ({ data }) => {
  const language = useSelector((state: RootState) => state.inner.language)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPage(0))
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <Banner
        title={data.drupal[language.toLowerCase()].title}
        image={data.drupal[language.toLowerCase()].fieldImg}
      />
      <AboutUs />
      <Services />
      {/* 
      <Presentation />
      <Promotions /> \
      */}
      <BrandList hasBackground={true} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
    query {
      drupal {
        it: nodeById(id: "13", language: IT) {
          title
          ... on Drupal_NodePaginaBase {
            fieldImg {
              url
              alt
            }
          }
        }
        en: nodeById(id: "13", language: EN) {
          title
          ... on Drupal_NodePaginaBase {
            fieldImg {
              url
              alt
            }
          }
        }
      }
    }
`