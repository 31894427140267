import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { Fade } from "react-reveal"

const AboutUs: React.FC = () => {
  const language = useSelector((state: RootState) => state.inner.language)

  const data = useStaticQuery(graphql`
      query {
        customAdminHomeblockAdminHomeblockResourceIt {
          title
          img
          body
          link
        }
        customAdminHomeblockAdminHomeblockResourceEn {
          title
          img
          body
          link
        }
      }
    `)

  return (
    <div className="c-aboutUs">
      {
        language === 'IT' ?
          <>
            <div className="slide-in">
              <img src={data.customAdminHomeblockAdminHomeblockResourceIt.img} alt="preview" />
            </div>
            <div className="rellax" data-rellax-xs-speed="0" data-rellax-mobile-speed="0" data-rellax-tablet-speed="-1">
              <Fade bottom>
                <h2>{data.customAdminHomeblockAdminHomeblockResourceIt.title}</h2>
              </Fade>
              <Fade bottom>
                <div dangerouslySetInnerHTML={{ __html: data.customAdminHomeblockAdminHomeblockResourceIt.body }} />
              </Fade>
              <Fade bottom>
                <a href={'//' + data.customAdminHomeblockAdminHomeblockResourceIt.link} className="o-btn(arrowRight)">
                  Scopri di più
              </a>
              </Fade>
            </div>
          </>
          :
          <>
            <div className="slide-in">
              <img src={data.customAdminHomeblockAdminHomeblockResourceEn.img} alt="preview" />
            </div>
            <div className="rellax" data-rellax-xs-speed="0" data-rellax-mobile-speed="0" data-rellax-tablet-speed="-1">
              <Fade bottom>
                <h2>{data.customAdminHomeblockAdminHomeblockResourceEn.title}</h2>
              </Fade>
              <Fade bottom>
                <div dangerouslySetInnerHTML={{ __html: data.customAdminHomeblockAdminHomeblockResourceEn.body }} />
              </Fade>
              <Fade bottom>
                <a href={'//' + data.customAdminHomeblockAdminHomeblockResourceEn.link} className="o-btn(arrowRight)">
                  Find out more
              </a>
              </Fade>
            </div>
          </>
      }
    </div>
  )
}

export default AboutUs
